import CatalogImage from 'components/MediaServerImage/CatalogImage';
import CustomDirectorySearchBox from 'components/Artist/primitives/CustomDirectorySearchBox';
import Dropdown from 'components/Dropdown/ArtistDropdown';
import Featuring from 'shared/ui/components/Featuring';
import Head from './Head';
import NavLink from 'components/NavLink';
import PageBody, { ViewName } from 'views/PageBody';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import Section from 'components/Section';
import SelectBox from 'web-ui/templates/FormElements/SelectBox';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Genre } from 'state/Genres/types';
import { get } from 'lodash-es';
import { MOST_POPULAR_ARTISTS_CATEGORY_ID } from 'state/Recs/constants';
import { Navigate } from 'state/Routing/types';
import { Rec } from 'state/Recs/types';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

export type Props = {
  currentPath: string;
  favoriteStations: Array<any>;
  genre: Genre & {
    title: string;
  };
  genres: Array<Genre>;
  navigate: Navigate;
  recs: Array<
    Rec & {
      name: string;
      seedId: number;
      seedType: 'artist';
    }
  >;
  supportsSocialConnect: boolean;
};

function ArtistDirectory({
  currentPath,
  favoriteStations,
  genre,
  genres,
  navigate,
  recs,
  supportsSocialConnect,
}: Props) {
  const translate = useTranslate();

  const social = {
    supportsConnect: supportsSocialConnect,
    url: currentPath,
  };

  return recs.length ?
      <>
        <Head />
        <PageBody
          dataTest={ViewName.ArtistDirectory}
          social={social}
          title={translate('Create Artist Radio Stations')}
        >
          <CustomDirectorySearchBox data-test="custom-directory-search-box">
            <SelectBox
              name="genre"
              onChange={(path: string) => navigate({ path })}
              options={genres}
              selectClasses="short"
              selectedOption={genre}
              tabindex={2}
            />
          </CustomDirectorySearchBox>
          <Section
            as="h2"
            header={translate('Popular {genre} Artists', {
              genre:
                genre.id === MOST_POPULAR_ARTISTS_CATEGORY_ID ?
                  ''
                : genre.title,
            })}
          >
            <Tiles tilesInRow={3}>
              {recs.map(artist => {
                const { name, url, seedType, seedId, imgWidth } = artist;
                const isFavorite = favoriteStations.filter(
                  station => get(station, 'seedId') === seedId,
                );
                const description = (
                  <Featuring
                    artistId={seedId}
                    key={`featuring${seedId}`}
                    numberOfFeatures={1}
                  />
                );

                return (
                  <Tile
                    dropdown={
                      <Dropdown
                        artist={artist}
                        followed={!!isFavorite.length}
                        key={`artist-${seedId}`}
                      />
                    }
                    isRoundImage
                    key={seedId}
                    subTitle={description}
                    title={name}
                    url={url}
                  >
                    <NavLink
                      css={{ display: 'block', position: 'relative' }}
                      to={url}
                    >
                      <PlayButtonContainerPrimitive data-test="play-button-container">
                        <PlayButton
                          artistName={name}
                          className="play"
                          deferPlay={!!url}
                          playedFrom={
                            genre.id === MOST_POPULAR_ARTISTS_CATEGORY_ID ?
                              PLAYED_FROM.DIR_CUSTOM_MAIN_FEATURED_ARTISTS
                            : PLAYED_FROM.DIR_CUSTOM_FILTERED
                          }
                          seedId={seedId}
                          stationId={seedId}
                          stationType={seedType}
                        />
                      </PlayButtonContainerPrimitive>
                      <CatalogImage
                        alt={name}
                        aspectRatio={1}
                        height={imgWidth}
                        id={seedId}
                        type={seedType}
                        width={imgWidth}
                      />
                    </NavLink>
                  </Tile>
                );
              })}
            </Tiles>
          </Section>
        </PageBody>
      </>
    : null;
}

export default ArtistDirectory;
