import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const CustomDirectorySearchBox = styled('div')(({ theme }) => ({
  marginBottom: '1.5rem',
  width: '33.33%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    width: '100%',
  },
}));

export default CustomDirectorySearchBox;
